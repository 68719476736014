let vue = new Vue({
  el: '#app',
  data: {
    fromQrCode: false,
    creditsFocus: false,
    trailerCover: false,
    creditsSidebar: false,
    actionsSidebar: false,
    actions: [
      {
        text: '<p><span @click="test">test</span></p>',
        click: () => {
          console.log('wow')
        }
      }
    ]
  },
  methods: {
    stopVideo: function() {
      // Stop video by resetting src attribute
      // Hacky but works?
      this.$refs.trailerFrame.src = this.$refs.trailerFrame.src;
      this.trailerCover = false;
    }
  },
  computed: {
    paddingRight() {
      return 'padding-right: 100px;'
    }
  },
  created() {
    let urlParams = new URLSearchParams(window.location.search.substring(1));
    this.fromQrCode = urlParams.get("qr") == 'y' ? true : false
    this.creditsFocus = urlParams.get("credits") == 'y' ? true : false
  },
  mounted() {
    if (this.fromQrCode) {
      if (window.innerWidth <= 700) {
        $('html, body').animate({
          scrollTop: $('.footer-actions-content').offset().top - 50
        }, 3000);
      } else {
        this.actionsSidebar = true
      }
    }
    if (this.creditsFocus) {
      if (window.innerWidth <= 700) {
        $('html, body').animate({
          scrollTop: $('.footer-credits-content').offset().top - 50
        }, 1500);
      } else {
        this.creditsSidebar = true
      }
    }
  }
});

$(document).ready(() => {

  // Mobile arrow scroll
  $('.arrow').click(() => {
    $('html, body').animate({
      scrollTop: window.innerHeight
    }, 1000);
  });

  // Auto title switcher
  let titles = ['Water Shield', 'Warburdar Bununu'];
  let t = $('.title');
  setInterval(() => {
    t.text(t.text() === titles[0] ? titles[1] : titles[0]);
  }, 8000);

  // Owl background carousel
  let owl = $('.owl-carousel');
  owl.owlCarousel({
    items: 1,
    loop: true,
    autoplay: true,
    autoplayTimeout: 8000,
    nav: false,
    lazyLoadEager: 4,
    rewind: false,
    checkVisibility: false,
    touchDrag: false,
    mouseDrag: false,
    pullDrag: false,
    freeDrag: false,
    responsiveRefreshRate: 10
  });

});